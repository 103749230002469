<script lang="ts">
  import PageTitle from "../components/PageTitle.svelte";
</script>

<div class="page-cont">
  <div class="text">
    <PageTitle title="Work Experience" />
    <div class="subtitle">Pubmark (2023/01/09 - 2023/07/07)</div>
    I worked for Pubmark as a software engineer. This was a co-op that ran from early
    January to early June, 2023. I was on the Partner Products team, where we developed
    and maintained web services for BookBub partners (authors and publishers). The
    team also maintained their own ads platform.

    <br />

    Some new technologies I worked with while at Pubmark include: <br />

    <ul>
      <li>Ruby on Rails</li>
      <li>Clojure</li>
      <li>Nix</li>
      <li>Snowflake (data warehouse)</li>
      <li>Apache Airflow</li>
      <li>AWS</li>
    </ul>

    <br />
    Pubmark was an amazing experience for me, I got exposure to a large new technology
    in Rails, and I got access to some amazing and knowledgable people for questions.
    I feel like I really furthered my skills and knowledge as a developer, I picked
    up some new tools, and improved my workflow greatly.
    <br />

    <div class="subtitle">Wayfair (2022/01/10 - 2022/06/24)</div>
    I worked at Wayfair as a software engineer. This was my first co-op. I was a
    part of SEO Impressions team. The team managed a bunch of micro services using
    a few different tech stacks. This was perfect for me as a student trying to learn
    as much as possible.

    <br />

    A list of technologies I gained experience with at Wayfair:
    <br />

    <ul>
      <li>Docker & K8s</li>
      <li>Spring Boot</li>
      <li>Kafka with Avro Schemas</li>
      <li>DataDog</li>
      <li>Google Cloud Platform</li>
      <li>Kibana</li>
      <li>Jira</li>
      <li>Buildkite and Sonarcube</li>
      <li>Little bit of Cypress</li>
    </ul>

    <br />
    Super fun experience; I learned a lot about different technologies and got to
    experience how a large(er) tech company operates.
  </div>
</div>
