<script lang="ts">
  import PageTitle from "../components/PageTitle.svelte";
</script>

<div class="page-cont">
  <div class="text">
    <PageTitle title="About" />
    Hey, I'm just a guy on the internet who likes programming way too much. I made
    this website when I was in college looking for a job. I'd be surprised if any
    recruiters actually looked at it, but it was fun to make. It used to have some
    content that expanded on my resume. But that's gone out of date, and so did this
    information, so I've removed it. Kinda want to turn this site into a blog one
    day, but until then, I'd recommend just checking out my github activity.
  </div>
</div>
